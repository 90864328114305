import classNames from 'classnames'
import find from 'lodash/find'
import {
  FunctionComponent,
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import { PiCaretDown, PiCheck, PiMagnifyingGlass, PiPalette } from 'react-icons/pi'
import ReactSelect, { FormatOptionLabelMeta, OnChangeValue, Props, SelectInstance, components } from 'react-select'

import { getAppRootElement } from 'src/utils'

import { BlankButton } from './Buttons'

export interface SelectAttributes extends SelectHTMLAttributes<HTMLSelectElement> {
  isRequired?: boolean
  isChanged?: boolean
  hasError?: boolean
  options?: { id: string; name: string; isDisabled?: boolean }[]
  readOnly?: boolean
}

export const Select = ({
  className,
  isRequired = false,
  options = [],
  isChanged = false,
  placeholder,
  hasError = false,
  ...props
}: SelectAttributes) => (
  <div className={classNames('relative', className)}>
    <select
      required={isRequired}
      className={classNames(
        'block appearance-none w-full p-2 pr-8 print:hidden',
        ...getFormBoxStyles(isChanged, props.disabled, hasError, props.readOnly),
      )}
      {...props}
    >
      {placeholder && (
        <option disabled={isRequired} value="">
          {props.readOnly ? '' : placeholder}
        </option>
      )}
      {options?.map((f) => (
        <option key={f.id} value={f.id} disabled={f.isDisabled}>
          {f.name}
        </option>
      ))}
    </select>
    <p className="w-full p-1 hidden print:inline-block">
      {options?.find((o) => o.id?.toString() === props.value?.toString())?.name}
    </p>
    {!props.readOnly && (
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 print:hidden">
        <PiCaretDown className="h-6 w-6" />
      </div>
    )}
  </div>
)

export interface InputAttributes extends InputHTMLAttributes<HTMLInputElement> {
  isChanged?: boolean
  hasError?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputAttributes>(
  ({ className, isChanged = false, hasError = false, ...props }: InputAttributes, ref) => (
    <>
      <input
        ref={ref}
        className={classNames(
          'block w-full p-2 print:hidden',
          ...getFormBoxStyles(isChanged, props.disabled, hasError, props.readOnly),
          className,
        )}
        {...props}
      />
      <p className="w-full p-2 hidden print:block">{props.value}</p>
    </>
  ),
)

export const FileInput = forwardRef<HTMLInputElement, InputAttributes>(
  ({ className, isChanged = false, hasError = false, ...props }: InputAttributes, ref) => (
    <>
      <input
        ref={ref}
        className={classNames(
          'file:border-none file:whitespace-nowrap file:inline-block file:py-2 file:m-1 file:mr-3 file:min-w-120px',
          'file:uppercase file:font-semibold file:rounded file:tracking-wide file:print:hidden',
          'file:bg-teal-500 file:hover:bg-teal-600 file:active:bg-teal-700',
          'file:text-white file:hover:text-slate-50 file:active:text-slate-100 file:text-xs',
          ...getFormBoxStyles(isChanged, props.disabled, hasError, props.readOnly),
          className,
        )}
        {...props}
      />
      <p className="w-full p-2 hidden print:block">{props.value}</p>
    </>
  ),
)

// A drop-in replacement for an input checkbox styled, but styled for the app
export const CheckboxSimple = (props: React.HTMLProps<HTMLInputElement>) => {
  return (
    <div className={'relative border-2 w-fit h-fit rounded bg-white border-slate-300 cursor-pointer'}>
      <PiCheck className={classNames('h-6 w-6', { invisible: !props.checked })} />
      <input
        type="checkbox"
        className="appearance-none absolute top-0 left-0 w-full h-full"
        onClick={(e) => {
          // prevent click from bubbling
          e.stopPropagation()
        }}
        {...props}
      />
    </div>
  )
}

interface CheckboxProps {
  value: boolean
  onClick?: (e: any) => void
  onChange?: (e: any) => void
  className?: string
  isChanged?: boolean
  hasError?: boolean
}

export const Checkbox = ({
  className = '',
  value,
  onClick = stopPropagation,
  onChange = () => {},
  isChanged = false,
  hasError = false,
  ...htmlInputElementProps
}: CheckboxProps & Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'value'>) => {
  const handleChange = useBooleanCheckboxChangeHandler(onChange, htmlInputElementProps.name, htmlInputElementProps.name)

  return (
    <div
      className={classNames(
        'border-2 relative',
        'cursor-pointer',
        ...getFormBoxStyles(
          isChanged,
          htmlInputElementProps.disabled,
          hasError,
          htmlInputElementProps.readOnly,
          'border-2 rounded',
        ),
        className,
      )}
      onClick={onClick}
    >
      <input
        id={htmlInputElementProps.name}
        type="checkbox"
        className="opacity-0 absolute w-full h-full pointer-events-none"
        onChange={handleChange}
        checked={value}
        {...htmlInputElementProps}
      />
      <PiCheck className={classNames('h-6 w-6', { invisible: !value })} />
    </div>
  )
}

interface CheckboxLabelProps {
  label: string
  className?: string
  required?: boolean
  children: React.ReactElement<CheckboxProps>
}

export const CheckboxLabel = ({ label, className = '', required, children }: CheckboxLabelProps) => {
  return (
    <label className={classNames('flex flex-row items-center', className)}>
      {children}
      <div className="ml-2">
        {label}
        {required && <span className="ml-1 text-red-700">*</span>}
      </div>
    </label>
  )
}

function useBooleanCheckboxChangeHandler(
  onChange: (event: { target: { type: string; name?: string; id?: string; value: string; checked: boolean } }) => void,
  name?: string,
  id?: string,
) {
  return useCallback(
    (e) =>
      onChange({
        target: {
          type: 'change',
          id,
          name,
          value: e.target.checked,
          checked: e.target.checked,
        },
      }),
    [name, id, onChange],
  )
}

export const RadioButton = ({
  isRequired = false,
  isChanged = false,
  hasError = false,
  className = '',
  checked,
  ...props
}) => (
  <div
    className={classNames(
      'relative p-1 rounded-100%',
      ...getFormBoxStyles(isChanged, props.disabled, hasError, props.readOnly, 'border-2 rounded'),
      className,
    )}
  >
    <input
      type="radio"
      className="opacity-0 absolute w-full h-full pointer-events-none"
      required={isRequired}
      checked={checked}
      {...props}
    />
    <div className={classNames('h-4 w-4 bg-current rounded-full', { invisible: !checked })} />
  </div>
)

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isChanged?: boolean
  hasError?: boolean
}

export const TextArea = ({ className, isChanged = false, hasError = false, ...props }: TextAreaProps) => (
  <>
    <textarea
      className={classNames(
        'block w-full p-2 print:hidden',
        ...getFormBoxStyles(isChanged, props.disabled, hasError, props.readOnly),
        className,
      )}
      {...props}
    />
    <p className="hidden print:block">{props.value}</p>
  </>
)

const getFormBoxStyles = (
  isChanged?: boolean,
  disabled?: boolean,
  hasError?: boolean,
  readOnly?: boolean,
  baseBorderClasses = 'border rounded',
) => [
  baseBorderClasses,
  'focus:outline-none print:border-0',
  {
    'bg-white focus:shadow-outline': !readOnly,
    'border-slate-300': !isChanged && !hasError,
    'border-teal-600': isChanged && !hasError,
    'hover:border-slate-400': !readOnly && !isChanged && !hasError,
    'hover:border-teal-700': !readOnly && isChanged && !hasError,
    'bg-slate-100 pointer-events-none text-slate-400': disabled && !hasError,
    'appearance-none bg-slate-100 border-slate-100 pointer-events-none': readOnly,
    'border-red-700 hover:border-red-800': hasError,
  },
]

type ReactSelectOption = { label: string; value: string | number }

export interface MultiSelectProps {
  name?: string
  className?: string
  id?: string
  value: (string | number)[]
  options?: {
    id: string | number
    name: string
  }[]
  onChange: (event: { target: { type: string; id?: string; name?: string; value: string[] } }) => void
  placeholder?: string
  isRequired?: boolean
}

export const MultiSelect = ({
  name,
  options = [],
  onChange,
  isRequired = false,
  className = '',
  value,
  id,
  ...props
}: MultiSelectProps) => {
  const mappedOptions = useMemo(
    () =>
      options.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [options],
  )

  const mappedValues = useMemo(() => {
    return value
      ?.map((v) => find(mappedOptions, (o) => v?.toString() === o.value?.toString()))
      .filter((v): v is ReactSelectOption => v !== undefined)
  }, [value, mappedOptions])

  const handleChange = useCallback(
    (value) => {
      onChange({
        target: {
          type: 'change',
          id,
          name,
          value: (value ?? []).map((v) => v.value),
        },
      })
    },
    [onChange, name, id],
  )

  const selectRef = useRef<SelectInstance<ReactSelectOption>>(null)

  return (
    <div className={classNames('relative', className)}>
      {isRequired && (
        <input
          tabIndex={-1}
          onChange={noop}
          className="opacity-0 w-full h-1 absolute"
          autoComplete="off"
          value={value?.map((v) => v.toString())}
          onFocus={() => selectRef.current?.focus()}
          required
        />
      )}
      <ReactSelect
        {...props}
        id={id}
        name={name}
        options={mappedOptions}
        onChange={handleChange}
        value={mappedValues}
        menuPortalTarget={getAppRootElement()}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 4 }) }}
        menuPosition="fixed" // prevents incorrect positioning in modals which are in a portal in their own
        isMulti
        components={{ DropdownIndicator }}
      />
    </div>
  )
}

export type RichSelectOptionType = { value: string; label: string }

export interface RichSelectProps<T extends RichSelectOptionType> extends Omit<Props<T>, 'onChange'> {
  options: T[]
  labelComponent?: (option: T, labelMeta: FormatOptionLabelMeta<T>) => ReactNode
  onChange: (event: { target: { type: string; name?: string; id?: string; value: OnChangeValue<T, false> } }) => void
  name?: string
  className?: string
  id?: string
  placeholder?: string
}

export function RichSelect<T extends RichSelectOptionType>({
  name,
  id,
  options = [],
  onChange,
  labelComponent,
  isSearchable = false,
  isClearable = false,
  isRequired = false,
  className = '',
  value,
  ...props
}: RichSelectProps<T>): JSX.Element {
  const handleChange = useCallback(
    (selectedOption: OnChangeValue<T, false>) =>
      onChange({ target: { type: 'change', name, id, value: selectedOption } }),
    [onChange, name, id],
  )

  const selectRef = useRef<SelectInstance<T>>(null)

  return (
    <div className={classNames('relative', className)}>
      <ReactSelect
        classNamePrefix="react-select"
        className="h-full w-full react-select"
        ref={selectRef}
        id={id}
        name={name}
        options={options}
        value={value}
        onChange={handleChange}
        {...props}
        menuPortalTarget={getAppRootElement()}
        menuPosition="fixed" // prevents incorrect positioning in modals which are in a portal in their own
        formatOptionLabel={labelComponent}
        styles={{
          control: (provided) => ({ ...provided, height: '100%' }),
          valueContainer: (provided) => ({ ...provided, height: '100%' }),
          menuPortal: (base) => ({ ...base, zIndex: 4 }),
        }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        components={isSearchable && { DropdownIndicator }}
      />
      {isRequired && (
        <input
          tabIndex={-1}
          className="opacity-0 w-full h-0 absolute"
          autoComplete="off"
          onChange={noop}
          value={value}
          onFocus={() => selectRef.current?.focus()}
          required
        />
      )}
    </div>
  )
}

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <PiMagnifyingGlass />
      </components.DropdownIndicator>
    )
  )
}

function noop() {}

export const ColorSelectButton = ({
  onClick,
  colorClassName = '',
  colorName = '',
  isChanged = false,
  disabled = false,
  hasError = false,
  readOnly = false,
}) => (
  <BlankButton
    onClick={onClick}
    variantColor="darkGray"
    size="base"
    className={classNames(
      'relative p-2 pr-8 leading-tight',
      ...getFormBoxStyles(isChanged, disabled, hasError, readOnly),
    )}
  >
    <div className="flex items-center space-x-2">
      <div className={classNames('h-6 w-6 rounded-100%', { 'soft-shadow-outline': colorClassName }, colorClassName)}>
        &nbsp;{/*So text line height would apply to element height the same way as with other form fields*/}
      </div>
      <div>{colorName}</div>
    </div>

    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
      <PiPalette className="inline-block h-4 w-4 mx-1 text-slate-600" />
    </div>
  </BlankButton>
)

export const ErrorMessageElement: FunctionComponent<{}> = (props) => {
  return <div className="text-xs text-red-600 leading-none p-1">{props.children}</div>
}

function stopPropagation(e) {
  e.stopPropagation()
}
