// cleanQuery cleans a query string by reducing multiple whitespaces to a
// single one and trimming leading and trailing whitespace, to not cache
import { downloadUri } from 'src/utils'

import { parseResponse, readDataUri } from './query'

// similar queries multiple times.
export function cleanQuery(value?: string) {
  return value?.replaceAll(/\s+/g, ' ').trim() || undefined
}

export async function downloadResponseAttachment(response) {
  const fileName = response.headers.get('content-disposition')?.match(/filename="([^"]+)"/)?.[1] ?? 'export.xlsx'
  const blob = await parseResponse(response, 'blob')
  const dataUri = await readDataUri(blob)
  downloadUri(dataUri, fileName)
}
