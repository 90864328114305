import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { IconButton } from 'src/components/Buttons'
import { AltLogoIcon } from 'src/icons'

export function SurveyPageHeader({ className = undefined, url }) {
  return (
    <div
      className={classNames(
        'flex items-center justify-between w-full border-b border-slate-200 text-sky-900 pr-8',
        className,
      )}
    >
      <IconButton as={Link} to={url} className="p-3 w-20 flex-grow-0" variantColor="darkYellow" icon={AltLogoIcon} />
    </div>
  )
}
