import { useResolvedPath } from 'react-router-dom'

import { SurveyPageHeader } from 'src/components/Surveys/SurveyPageHeader'
import TrackingConsent from 'src/components/TrackingConsent'

export function SurveyPage({ children }) {
  const url = useResolvedPath('').pathname
  return (
    <div className="w-full">
      <SurveyPageHeader url={url} />
      <div className="p-8 bg-slate-50">{children}</div>
      <TrackingConsent />
    </div>
  )
}
