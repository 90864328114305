import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PiInfoFill } from 'react-icons/pi'

import { Button, IconButton } from 'src/components/Buttons'
import { useTrackingConsentCookie } from 'src/hooks/cookie'
import { setGaStorageConsent } from 'src/utils'

import { SmallSpinner } from './CenterSpinner'

declare global {
  interface Window {
    dataLayer: Array<any>
    gtag: (...args: any[]) => void
  }
}

export default function TrackingConsent() {
  const { t } = useTranslation()
  const [isClosed, setClosed] = useState(false)
  const { isConsented, hasTcConsent, isConsentLoading, setCookieConsent } = useTrackingConsent()

  useEffect(() => {
    // load from memory
    if (isConsented === null) {
      setClosed(false)
    } else {
      setGaStorageConsent(isConsented)
      setClosed(true)
    }
  }, [isConsented, setClosed])

  const handleSetConsent = useCallback(
    (newIsConsented) => {
      setCookieConsent(newIsConsented)
      setClosed(true)
    },
    [setCookieConsent, setClosed],
  )

  return isConsentLoading ? (
    <div className="fixed bottom-0 left-0 w-8 h-8 p-2 m-2 space-y-2 bg-teal-600 rounded shadow">
      <SmallSpinner className="w-4 h-4 text-white" />
    </div>
  ) : hasTcConsent ? null : isClosed ? (
    <IconButton
      onClick={() => setClosed(false)}
      icon={PiInfoFill}
      variantColor="white"
      className="fixed bottom-0 left-0 md:left-[210px] w-8 h-8 p-2 m-2 space-y-2 bg-teal-600 rounded shadow"
    />
  ) : (
    <div className="fixed bottom-0 left-0 p-2 m-2 space-y-2 bg-teal-600 rounded shadow  flex flex-col items-center">
      <div className="flex space-x-2 text-sm text-white items-start">
        <IconButton
          className="left-0 w-4 h-4"
          onClick={() => setClosed(false)}
          icon={PiInfoFill}
          variantColor="white"
        />
        <Trans parent="div" i18nKey="TRACKING_CONSENT">
          <p className="font-semibold">
            Do you agree to store tracking cookies in this website for our analytics partner (Google Analytics)?
          </p>
          <p>We use tracking cookies to analyze traffic on this site.</p>
          <p>
            Tracking cookies may be combined with other information that you've provided to our <br />
            analytics partner or that they've collected from your use of their services.
          </p>
        </Trans>
      </div>
      <div className="space-x-2">
        <Button onClick={() => handleSetConsent(false)}>No</Button>
        <Button variantColor="white" onClick={() => handleSetConsent(true)}>
          {t('Yes')}
        </Button>
      </div>
    </div>
  )
}

function useTrackingConsent() {
  const [isCookieConsented, setCookieConsent] = useTrackingConsentCookie()
  return useMemo(() => {
    return {
      isConsented: isCookieConsented,
      hasTcConsent: false, // disabled until T&C explicitly includes tracking, see commit history
      isConsentLoading: false,
      setCookieConsent,
    }
  }, [isCookieConsented, setCookieConsent])
}
