import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import {
  PiBinoculars,
  PiBuilding,
  PiBuildingOffice,
  PiChartDonut,
  PiClipboardText,
  PiFactory,
  PiRecycle,
  PiShoppingCart,
  PiSpinner,
  PiTag,
  PiTruck,
  PiUsersThree,
  PiWarehouse,
} from 'react-icons/pi'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { RoleId } from 'src/api'
import { LanguageSwitcher } from 'src/components/App/LanguageSwitcher'
import { FullLogoIcon, InventoryIcon } from 'src/icons'
import { useMenuRoutes } from 'src/route-config'
import useActiveRole from 'src/state/role'

export function AppSidebar({ closeMenu }) {
  const [roleQuery] = useActiveRole()
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className="p-2 flex flex-col h-full space-y-2">
      <div
        className="w-full flex justify-center py-4 cursor-pointer"
        onClick={() => {
          navigate('/')
          closeMenu()
        }}
      >
        <FullLogoIcon className="h-6" />
      </div>
      <div className="overflow-y-auto space-y-2 grow">
        <SidebarButton label={t('MAIN_MENU`Users')} icon={PiUsersThree} link="/admin/users" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Users')} icon={PiUsersThree} link="/users" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Users')} icon={PiUsersThree} link="/brand/users" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Users')} icon={PiUsersThree} link="/project/users" onClick={closeMenu} />
        <SidebarButton
          label={t('MAIN_MENU`Organisations')}
          icon={PiBuilding}
          link="/admin/organisations"
          onClick={closeMenu}
        />
        <SidebarButton label={t('MAIN_MENU`Profile')} icon={PiClipboardText} link="/profile" onClick={closeMenu} />
        <SidebarButton
          label={t('MAIN_MENU`Profile')}
          icon={PiClipboardText}
          link="/handler-profile"
          onClick={closeMenu}
        />
        <SidebarButton
          label={t('MAIN_MENU`Profile')}
          icon={PiClipboardText}
          link="/manufacturer-profile"
          onClick={closeMenu}
        />
        <SidebarButton
          label={t('MAIN_MENU`Manufacturers')}
          icon={PiBuildingOffice}
          link="/suppliers-overview"
          onClick={closeMenu}
        />
        <SidebarButton label={t('MAIN_MENU`Inventory')} icon={InventoryIcon} link="/inventory" onClick={closeMenu} />
        <SidebarButton
          label={t('MAIN_MENU`Inventory')}
          icon={InventoryIcon}
          link="/inventory/facility/:id"
          onClick={closeMenu}
        />
        <SidebarButton
          label={t('MAIN_MENU`Inventory')}
          icon={InventoryIcon}
          link="/admin/inventory"
          onClick={closeMenu}
        />
        <SidebarButton
          label={t('MAIN_MENU`Facilities')}
          icon={PiBuildingOffice}
          link="/facilities"
          onClick={closeMenu}
        />
        <SidebarButton
          label={
            roleQuery.data?.roleId === RoleId.BrandManager
              ? t('MAIN_MENU`My Manufacturers')
              : t('MAIN_MENU`Manufacturers')
          }
          icon={PiBuildingOffice}
          link="/manufacturers"
          onClick={closeMenu}
        />
        <SidebarButton label={t('MAIN_MENU`Brands')} icon={PiTag} link="/admin/brands" onClick={closeMenu} />
        <SidebarButton
          label={t('MAIN_MENU`Manufacturers')}
          icon={PiBuildingOffice}
          link="/admin/manufacturers"
          onClick={closeMenu}
        />
        <SidebarButton label={t('MAIN_MENU`Handlers')} icon={PiWarehouse} link="/admin/handlers" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Handlers')} icon={PiWarehouse} link="/handlers" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Recyclers')} icon={PiRecycle} link="/admin/recyclers" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Organisation')} icon={PiBuilding} link="/organization" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Recyclers')} icon={PiRecycle} link="/recyclers" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Shipments')} icon={PiTruck} link="/shipments" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Shipments')} icon={PiTruck} link="/admin/shipments" onClick={closeMenu} />
        <SidebarButton label={t('MAIN_MENU`Statistics')} icon={PiChartDonut} link="/statistics" onClick={closeMenu} />
        <SidebarButton
          label={t('MAIN_MENU`Purchase orders')}
          icon={PiShoppingCart}
          link="/admin/purchase-orders"
          onClick={closeMenu}
        />
        <SidebarButton
          label={t('MAIN_MENU`Purchase orders')}
          icon={PiShoppingCart}
          link="/purchase-orders"
          onClick={closeMenu}
        />
        <SidebarButton label={t('MAIN_MENU`Production')} icon={PiFactory} link="/production" onClick={closeMenu} />
        <SidebarButton
          label={t('MAIN_MENU`Production')}
          icon={PiFactory}
          link="/admin/production"
          onClick={closeMenu}
        />
        <SidebarButton
          label={t('MAIN_MENU`Transaction overview')}
          icon={PiBinoculars}
          link="/admin/transaction-overview"
          onClick={closeMenu}
        />
        <SidebarButton
          label={t('MAIN_MENU`Transaction overview')}
          icon={PiBinoculars}
          link="/transaction-overview"
          onClick={closeMenu}
        />
      </div>
      <LanguageSwitcher onLanguageChanged={closeMenu} />
    </div>
  )
}

interface CountrySidebarButtonProps {
  label: string
  icon: IconType
  link: string
  onClick?: () => void
}

function SidebarButton({ label, icon: Icon, link, onClick }: CountrySidebarButtonProps) {
  const { menuRoutes, isLoading } = useMenuRoutes()
  const location = useLocation()
  const selected = matchPath({ path: link, end: false }, location.pathname)

  const navigate = useNavigate()
  if (!menuRoutes.includes(link)) {
    return null
  }

  if (isLoading) {
    Icon = PiSpinner
    label = 'Loading...'
  }

  return (
    <div
      className={classNames(
        {
          'bg-rr-sidebar-button hover:brightness-95 active:brightness-90': selected,
          'bg-white hover:brightness-95 active:brightness-90': !selected,
        },
        'flex flex-row items-center space-x-4 px-4 py-2 rounded-xl cursor-pointer',
      )}
      onClick={() => {
        navigate(link)
        onClick?.()
      }}
    >
      <Icon
        className={classNames('w-6 h-6', {
          'text-rr-sidebar-text': !selected,
          'text-rr-sidebar-icon-selected': selected,
        })}
      />
      <div
        className={classNames('text-base', {
          'text-rr-sidebar-text': !selected,
          'text-rr-sidebar-text-selected': selected,
        })}
      >
        {label}
      </div>
    </div>
  )
}
