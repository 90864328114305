import { useCallback, useRef } from 'react'

export function useDebounceFunction(fn: (...params: any[]) => Promise<any>, timeMs: number = 500) {
  const debounceTimeout = useRef<NodeJS.Timeout>()
  const rejectRef = useRef<() => void>()
  return useCallback(
    (...params: any[]) =>
      new Promise((resolve, reject) => {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current)
        }
        rejectRef.current?.() // reject previous promise (will be a no-op if it was resolved)
        rejectRef.current = reject
        debounceTimeout.current = setTimeout(() => fn(...params).then(resolve), timeMs)
      }),
    [fn, timeMs],
  )
}
