import { Suspense, lazy, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import AppFrame from 'src/components/AppFrame'
import CenterSpinner from 'src/components/CenterSpinner'
import { WasteHandlerSurveyLandingPage } from 'src/components/Surveys/WasteHandler/WasteHandlerSurveyLandingPage'
import { featureRMDFMonthly, featureWasteHandlerSurvey } from 'src/features'
import { PrivateRoutes, RoleSpecificRoutes } from 'src/route-config'
import {
  useAnalyticsUserConfigurationEffect,
  useBugsnagUserAttributes,
  useBugsnagUserConfigurationEffect,
  useGoogleAnalyticsUserId,
  useHelpscoutUserAttributes,
  usePageTracking,
  usePostHogIdentify,
  useSupportWidgetPageTracking,
  useSupportWidgetWithAttributes,
} from 'src/utils'

const WasteHandlerSurvey = lazy(() =>
  import('src/components/Surveys/WasteHandler/WasteHandlerSurvey').then((module) => ({
    default: module.WasteHandlerSurvey,
  })),
)
const Home = lazy(() => import('./Home'))
const CurrentFacilityInventory = lazy(() => import('./CurrentFacilityInventory'))
const CurrentFacilityPurchaseOrders = lazy(() => import('./CurrentFacilityPurchaseOrders'))
const AdminPurchaseOrders = lazy(() => import('./admin/purchase-orders'))
const CurrentOrganizationInventories = lazy(() => import('./CurrentOrganizationInventories'))
const CurrentOrganizationUsers = lazy(() => import('./CurrentOrganizationUsers'))
const CurrentProjectUsers = lazy(() => import('./CurrentProjectUsers'))
const Shipments = lazy(() => import('./Shipments'))
const RmdfPageMonthly = lazy(() => import('src/routes/RmdfPageMonthly'))
const ProfileRoute = lazy(() => import('./profile'))
const FacilityList = lazy(() => import('./FacilityList'))
const UserSettings = lazy(() => import('./UserSettings'))
const Organization = lazy(() => import('./Organization'))
const SignUpSurvey = lazy(() => import('./SignUpSurvey'))
const AdminUserList = lazy(() => import('./admin/users'))
const AdminOrganisationsPage = lazy(() => import('./admin/organisations'))
const AdminOrganisationDetailPage = lazy(() => import('./admin/organisationDetail'))
const AdminHandlers = lazy(() => import('./admin/handlers'))
const AdminTransactionOverview = lazy(() => import('./admin/transaction-overview'))
const TransactionOverview = lazy(() => import('./transaction-overview'))
const SignUp = lazy(() => import('./SignUp'))
const LogIn = lazy(() => import('./LogIn'))
const AdminBrandList = lazy(() => import('./admin/brands'))
const AdminManufacturerList = lazy(() => import('./admin/manufacturers'))
const AdminRecyclerList = lazy(() => import('./admin/recyclers'))
const AdminShipmentList = lazy(() => import('./admin/shipments'))
const ProfileSurvey = lazy(() => import('./ProfileSurvey'))
const ManufacturerList = lazy(() => import('./ManufacturerList'))
const Recyclers = lazy(() => import('./Recyclers'))
const CurrentBrandUsers = lazy(() => import('./CurrentBrandUsers'))
const CurrentBrandInventory = lazy(() => import('./CurrentBrandInventory'))
const AdminInventory = lazy(() => import('./admin/inventory'))
const HandlerList = lazy(() => import('./HandlerList'))
const Statistics = lazy(() => import('./Statistics'))
const Suppliers = lazy(() => import('./Suppliers'))
const SupplierList = lazy(() => import('./SupplierList'))
const RecycledProducts = lazy(() => import('./RecycledProducts'))
const AdminRecycledProducts = lazy(() => import('./admin/production'))

export function useRemoveTrailingSlash() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const { pathname, search } = location
    if (pathname !== '/' && pathname.endsWith('/')) {
      const newPath = pathname.slice(0, -1)
      navigate(newPath + search, { replace: true })
    }
  }, [location, navigate])
}

export default function AppRoutes() {
  useSupportWidgetWithAttributes(useHelpscoutUserAttributes())
  useBugsnagUserConfigurationEffect(useBugsnagUserAttributes())
  useAnalyticsUserConfigurationEffect(useGoogleAnalyticsUserId())
  usePostHogIdentify()
  usePageTracking()
  useSupportWidgetPageTracking()
  useRemoveTrailingSlash()
  return (
    <Suspense fallback={<CenterSpinner />}>
      <Routes>
        {featureWasteHandlerSurvey() && (
          <Route path="waste-handler-survey" element={<WasteHandlerSurveyLandingPage />} />
        )}
        {featureWasteHandlerSurvey() && (
          <Route path="waste-handler-survey/:surveyId" element={<WasteHandlerSurvey />} />
        )}
        <Route path="manufacturer-survey/*" element={<ProfileSurvey />} />
        <Route
          path="*"
          element={
            <AppFrame>
              <Suspense fallback={<CenterSpinner />}>
                <Routes>
                  <Route element={<PrivateRoutes />}>
                    <Route path="user" element={<UserSettings />} />
                  </Route>
                  <Route element={<RoleSpecificRoutes />}>
                    <Route
                      path="sign-up/handler-profile/:signUpCode"
                      element={<SignUp signUpSource="HANDLER_SURVEY" />}
                    />
                    <Route
                      path="sign-up/recycler-profile/:signUpCode"
                      element={<SignUp signUpSource="RECYCLER_SURVEY" />}
                    />
                    <Route
                      path="sign-up/manufacturer-profile/:signUpCode"
                      element={<SignUp signUpSource="MANUFACTURER_SURVEY" />}
                    />
                    <Route path="sign-up/:signUpCode?" element={<SignUp />} />
                    <Route path="organization/*" element={<Organization successRedirectUrl="/facilities" />} />
                    <Route path="users" element={<CurrentOrganizationUsers />} />
                    <Route
                      path="brand/users"
                      element={<CurrentBrandUsers signUpPathTemplate="/sign-up/manufacturer-profile/:signUpCode" />}
                    />
                    <Route path="project/users" element={<CurrentProjectUsers />} />
                    <Route path="admin/users/*" element={<AdminUserList />} />
                    <Route path="admin/organisations" element={<AdminOrganisationsPage />} />
                    <Route path="admin/organisations/:id" element={<AdminOrganisationDetailPage />} />
                    <Route path="inventory/facility/:id" element={<CurrentOrganizationInventories />} />
                    <Route path="facilities/*" element={<FacilityList recyclerPageUrl="/recyclers" />} />
                    <Route
                      path="manufacturer-profile"
                      element={
                        <SignUpSurvey
                          profilePathTemplate="/facilities/:id/profile/production"
                          facilityTypeId="MANUFACTURER"
                        />
                      }
                    />
                    <Route
                      path="handler-profile"
                      element={
                        <SignUpSurvey
                          profilePathTemplate="/facilities/:id/profile/general"
                          facilityTypeId="WASTE_HANDLER"
                        />
                      }
                    />
                    <Route
                      path="recycler-profile"
                      element={
                        <SignUpSurvey profilePathTemplate="/facilities/:id/profile/general" facilityTypeId="RECYCLER" />
                      }
                    />
                    <Route
                      path="inventory"
                      element={
                        <CurrentFacilityInventory
                          shipmentUrlPrefix="/shipments"
                          purchaseOrderUrlTemplate="/purchase-orders/:id/"
                          recycledProductUrlTemplate="/production/:id/"
                        />
                      }
                    />
                    <Route
                      path="purchase-orders/*"
                      element={<CurrentFacilityPurchaseOrders inventoryUrl="/inventory" />}
                    />
                    <Route
                      path="admin/purchase-orders/*"
                      element={<AdminPurchaseOrders inventoryUrl="/admin/inventory" />}
                    />
                    <Route path="brand/inventory/*" element={<CurrentBrandInventory />} />
                    <Route
                      path="shipments/rmdf/:sid/:bid/:year/:month"
                      element={
                        featureRMDFMonthly() ? (
                          <RmdfPageMonthly />
                        ) : (
                          <div className="text-xl p-4">Monthly RMDF to be released soon</div>
                        )
                      }
                    />
                    <Route
                      path="shipments/*"
                      element={
                        <Shipments
                          profileUrlTemplate="/suppliers/:id/profile"
                          handlerProfileUrlTemplate="/handlers/:id/profile"
                        />
                      }
                    />
                    <Route path="profile/*" element={<ProfileRoute recyclerPageUrl="/recyclers" />} />
                    <Route path="handlers/*" element={<HandlerList />} />
                    <Route path="admin/brands" element={<AdminBrandList />} />
                    <Route
                      path="admin/manufacturers/*"
                      element={<AdminManufacturerList shipmentUrlPrefix="/admin/shipments/outgoing" />}
                    />
                    <Route path="admin/handlers/*" element={<AdminHandlers shipmentUrlPrefix="/admin/shipments" />} />
                    <Route
                      path="admin/recyclers/*"
                      element={<AdminRecyclerList shipmentUrlPrefix="/admin/shipments" />}
                    />
                    <Route path="login" element={<LogIn />} />
                    <Route path="admin/shipments/*" element={<AdminShipmentList />} />
                    <Route
                      path="admin/inventory/*"
                      element={
                        <AdminInventory
                          inventoryPathTemplate="/admin/manufacturers/:id/inventory"
                          profilePathTemplate="/admin/manufacturers/:id/profile"
                        />
                      }
                    />
                    <Route path="admin/production/*" element={<AdminRecycledProducts />} />
                    <Route path="admin/transaction-overview" element={<AdminTransactionOverview />} />
                    <Route path="transaction-overview" element={<TransactionOverview />} />
                    <Route path="manufacturers/*" element={<ManufacturerList />} />
                    <Route path="statistics/*" element={<Statistics />} />
                    <Route path="recyclers/*" element={<Recyclers />} />
                    <Route path="suppliers/*" element={<Suppliers />} />
                    <Route path="suppliers-overview/*" element={<SupplierList />} />
                    <Route path="production/*" element={<RecycledProducts />} />
                  </Route>
                  <Route path="/" element={<Home />} />
                  <Route path=":path" element={<Home />} />
                  <Route path="logged-out" element={<Navigate to="/" />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Suspense>
            </AppFrame>
          }
        />
      </Routes>
    </Suspense>
  )
}
