import { Trans, useTranslation } from 'react-i18next'
import { PiCheckSquareFill } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import { useCreateSurvey } from 'src/api'
import { Button, TextAnchor } from 'src/components/Buttons'
import { SmallSpinner } from 'src/components/CenterSpinner'
import { SurveyPage } from 'src/components/Surveys/SurveyPage'

export function WasteHandlerSurveyLandingPage() {
  const navigate = useNavigate()
  const { mutateAsync: createSurvey, isLoading } = useCreateSurvey('waste-handler-survey', (surveyId) => {
    navigate(surveyId)
  })
  const { t } = useTranslation()

  return (
    <SurveyPage>
      <Trans parent="article" className="px-8 copy" i18nKey="WASTE_HANDLER_SURVEY_INTRO">
        <h1 className="text-3xl font-semibold text-sky-900 leading-tight pt-8">
          Welcome to the Reverse Resources Waste Handler Survey!
        </h1>
        <p>
          We at RR aim to accelerate the transition of the Textile & Apparel industry to a more circular and sustainable
          one.
        </p>
        <p>
          The survey is designed to be intuitive, user-friendly and should not take more than 20 minutes to fill in if
          you have most of the information at hand. All data collected will be kept confidential and only used in the
          scope of RR’s projects and initiatives.
        </p>
        <p>
          Shall you need any help or have any questions regarding the survey, feel free to contact us at{' '}
          <TextAnchor href="mailto:survey@reverseresources.net"> survey@reverseresources.net</TextAnchor> or use the
          chat box available in the lower corner of the page. We will be happy to help you with any queries.
        </p>
        <p>To begin the survey, simply click the “Begin Survey” button below. Thank you for your participation!</p>
      </Trans>
      <div className="px-8 text-center">
        <Button disabled={isLoading} icon={isLoading ? SmallSpinner : PiCheckSquareFill} onClick={() => createSurvey()}>
          {t('Begin survey')}
        </Button>
      </div>
    </SurveyPage>
  )
}
