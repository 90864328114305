import { useTranslation } from 'react-i18next'
import { PiTranslate } from 'react-icons/pi'

import { SelectField } from 'src/components/Fields'
import { useLanguageOptions } from 'src/hooks'

export function LanguageSwitcher({ onLanguageChanged }: { onLanguageChanged?: (code: string) => void }) {
  const [language, setLanguage, availableLanguages] = useLanguageOptions()
  const { t } = useTranslation()
  return (
    <div className="flex items-center space-x-2 w-full">
      <PiTranslate className="w-5 h-5" />
      <SelectField
        placeholder={t('Language')}
        isRequired
        value={language}
        options={availableLanguages}
        onChange={(e) => {
          setLanguage(e.target.value)
          onLanguageChanged?.(e.target.value)
        }}
        className="text-sm w-full"
      />
    </div>
  )
}
