import classNames from 'classnames'
import { useState } from 'react'

import { AppSidebar } from 'src/components/App/AppSidebar'
import { GlobalMessageProvider } from 'src/state/globalMessage'

import GlobalMessageModal from './GlobalMessageModal'
import Header from './Header'
import TrackingConsent from './TrackingConsent'

export default function TopFrame({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <section role="main" className="print:block grid grid-main h-full w-full">
      <GlobalMessageProvider>
        <Header toggleMenu={toggleMenu} className="grid-main-header print:hidden min-w-[600px]" />
        {isMenuOpen && (
          <div className="print:hidden md:hidden fixed inset-0 bg-black bg-opacity-50 z-40" onClick={closeMenu} />
        )}
        <div
          className={classNames(
            'print:hidden grid-main-sidebar h-full w-full bg-white border-r-[1px] border-rr-gray93',
            'fixed md:static md:translate-x-0 z-50 md:z-0',
            {
              'translate-x-0': isMenuOpen,
              '-translate-x-full': !isMenuOpen,
            },
          )}
        >
          <AppSidebar closeMenu={closeMenu} />
        </div>
        <div className="grid-main-body overflow-auto">{children}</div>
        <TrackingConsent />
        <GlobalMessageModal />
      </GlobalMessageProvider>
    </section>
  )
}
